<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="formData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { AppointmentNote } from "@/Modules/Models"
  import formConfig from "./formConfig"

  export default {
    name: "AppointmentNotesManagementForm",
    props: {
      dialogProps: { type: Object, required: true },
      appointmentNotesData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        updatedAppointmentNotesData: {},
        formConfig
      }
    },
    computed: {
      formData() {
        if (this.dialogProps.bookingData) {
          return this.updatedAppointmentNotesData
        }

        const appointmentNotesData = JSON.parse(JSON.stringify(this.appointmentNotesData))

        if (this.appointmentNotesData?.bilatACGreaterThanBC) {
          // If this field exists, we can assume that all the other fields exist too

        appointmentNotesData.weberRinneTestTable = {
          bilatACGreaterThanBC: this.appointmentNotesData.bilatACGreaterThanBC,
          midline: this.appointmentNotesData.midline,
          clBcAcInL: this.appointmentNotesData.clBcAcInL,
          clBcAcInR: this.appointmentNotesData.clBcAcInR,
          clAcBcInL: this.appointmentNotesData.clAcBcInL,
          clAcBcInR: this.appointmentNotesData.clAcBcInR,
          clLateralizeToL: this.appointmentNotesData.clLateralizeToL,
          clLateralizeToR: this.appointmentNotesData.clLateralizeToR,
          shLateralizeToL: this.appointmentNotesData.shLateralizeToL,
          shLateralizeToR: this.appointmentNotesData.shLateralizeToR,
          shLateralizeAwayFromL: this.appointmentNotesData.shLateralizeAwayFromL,
          shLateralizeAwayFromR: this.appointmentNotesData.shLateralizeAwayFromR,
          mhlBcAcInL: this.appointmentNotesData.mhlBcAcInL,
          mhlBcAcInR: this.appointmentNotesData.mhlBcAcInR,
          mhlAcBcInL: this.appointmentNotesData.mhlAcBcInL,
          mhlAcBcInR: this.appointmentNotesData.mhlAcBcInR,
          mhlLateralizeToL: this.appointmentNotesData.mhlLateralizeToL,
          mhlLateralizeToR: this.appointmentNotesData.mhlLateralizeToR,
          mhlLateralizeAwayFromL: this.appointmentNotesData.mhlLateralizeAwayFromL,
          mhlLateralizeAwayFromR: this.appointmentNotesData.mhlLateralizeAwayFromR,
        }
      }
        return appointmentNotesData
      },
      mode() {
        return this.dialogProps.mode
      }
    },
    watch: {
      appointmentNotesData: {
        handler() {

          if (this.dialogProps.bookingData) {
            if (this.dialogProps.bookingData.id) {
              this.updatedAppointmentNotesData.bookingId = this.dialogProps.bookingData.id
            }
            if (this.dialogProps.bookingData.locationId) {
              this.updatedAppointmentNotesData.locationId = this.dialogProps.bookingData.locationId
            }
            if (this.dialogProps.bookingData.patientId) {
              this.updatedAppointmentNotesData.patientId = this.dialogProps.bookingData.patientId
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {
      this.updatedAppointmentNotesData = this.appointmentNotesData

    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          let flatFormData = Form.getFlatData()

          if (flatFormData.weberRinneTestTable) {
            // flatten weberRinneTestTable and add it to the flatFormData
            flatFormData = {
              ...flatFormData,
              ...flatFormData.weberRinneTestTable
            }
          }

          if (flatFormData.epcErrorOther) {
            flatFormData.epcError = flatFormData.epcErrorOther
          }

          if (this.mode === "create") {
            await AppointmentNote.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.appointmentNotesData.id

            await AppointmentNote.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your Appointment Note has been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>