<template>
  <svg
      class="svg-icon"
      :fill="color"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 80"
      x="0px"
      y="0px">
    <g data-name="line expand">
      <path
          d="M25,19h3v3a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V19h3a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1H36V8a1,1,0,0,0-1-1H29a1,1,0,0,0-1,1v3H25a1,1,0,0,0-1,1v6A1,1,0,0,0,25,19Zm1-6h3a1,1,0,0,0,1-1V9h4v3a1,1,0,0,0,1,1h3v4H35a1,1,0,0,0-1,1v3H30V18a1,1,0,0,0-1-1H26Z" />
      <path d="M11,44H7a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V45A1,1,0,0,0,11,44Zm-1,4H8V46h2Z" />
      <path d="M11,52H7a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V53A1,1,0,0,0,11,52Zm-1,4H8V54h2Z" />
      <path d="M53,50h4a1,1,0,0,0,1-1V45a1,1,0,0,0-1-1H53a1,1,0,0,0-1,1v4A1,1,0,0,0,53,50Zm1-4h2v2H54Z" />
      <path d="M53,58h4a1,1,0,0,0,1-1V53a1,1,0,0,0-1-1H53a1,1,0,0,0-1,1v4A1,1,0,0,0,53,58Zm1-4h2v2H54Z" />
      <path d="M30,36h4a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v4A1,1,0,0,0,30,36Zm1-4h2v2H31Z" />
      <path d="M30,44h4a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v4A1,1,0,0,0,30,44Zm1-4h2v2H31Z" />
      <path d="M38,36h4a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1H38a1,1,0,0,0-1,1v4A1,1,0,0,0,38,36Zm1-4h2v2H39Z" />
      <path d="M38,44h4a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1H38a1,1,0,0,0-1,1v4A1,1,0,0,0,38,44Zm1-4h2v2H39Z" />
      <path d="M38,52h4a1,1,0,0,0,1-1V47a1,1,0,0,0-1-1H38a1,1,0,0,0-1,1v4A1,1,0,0,0,38,52Zm1-4h2v2H39Z" />
      <path d="M30,52h4a1,1,0,0,0,1-1V47a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v4A1,1,0,0,0,30,52Zm1-4h2v2H31Z" />
      <path d="M27,51V47a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4A1,1,0,0,0,27,51Zm-2-1H23V48h2Z" />
      <path d="M22,36h4a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1v4A1,1,0,0,0,22,36Zm1-4h2v2H23Z" />
      <path d="M22,44h4a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1v4A1,1,0,0,0,22,44Zm1-4h2v2H23Z" />
      <path
          d="M61,36H50V14a1,1,0,0,0-1-1H44.83a12.982,12.982,0,0,0-25.66,0H15a1,1,0,0,0-1,1V36H3a1,1,0,0,0-1,1V61a1,1,0,0,0,1,1H61a1,1,0,0,0,1-1V37A1,1,0,0,0,61,36Zm-1,2v2H50V38ZM48,15V25H40.3A12.977,12.977,0,0,0,45,15ZM32,4A11,11,0,1,1,21,15,11.013,11.013,0,0,1,32,4ZM16,15h3a13.038,13.038,0,0,0,4.708,10H16ZM4,38H14v2H4Zm0,4H14V60H4Zm12-1V27H27c.008,0,.015,0,.024,0a12.892,12.892,0,0,0,9.971,0H48V60H37V55a1,1,0,0,0-1-1H28a1,1,0,0,0-1,1v5H16ZM29,60V56h6v4Zm31,0H50V42H60Z" />
    </g>

  </svg>
</template>
<script>
  export default {
    name: "OrderManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>
<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>
