import GeneralPractitionersManagement from "./GeneralPractitionersManagement"
import GeneralPractitionersManagementIcon from "./components/GeneralPractitionersManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const GeneralPractitionersManagementModule = {
  moduleGrouping: "Customer Management",
  name: "General Practitioners",
  menuName: "General Practitioners",
  component: GeneralPractitionersManagement,
  path: "GeneralPractitionersManagement",
  icon: GeneralPractitionersManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View GeneralPractitionersManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("GeneralPractitionersManagement", GeneralPractitionersManagement)
    Vue.component("GeneralPractitionersManagementIcon", GeneralPractitionersManagementIcon)
  }
}
export default GeneralPractitionersManagementModule
