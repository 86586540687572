<template>
  <div class="status-column">
    <template v-if="scope.row.status">
      <el-tag
          :type="scope.row.status !== 'active' ? 'danger' : 'success'"
          disable-transitions>
        {{ scope.row.status }}
      </el-tag>
    </template>
    <template v-else>
      <el-tag type="info">
        inactive
      </el-tag>
    </template>
  </div>
</template>
<script>
export default {
  name: "ClientStatusColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
</style>
