<template>
  <div class="contact-details">
    <div class="flex-box">
      <div class="user-profile">
        <el-card class="box-card">
          <div class="avatar">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
            <div class="patient-name">
              {{ patientData.firstName }} {{ patientData.lastName }}
              <template v-if="hasDoB">
                <br>
                DOB: {{ dateOfBirth }} ({{ age }})
              </template>
              <div class="patient-id">
                #{{ patientData.id }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="overview">
        <div class="contact-details">
          <h3>Patient Details</h3>
          <dl v-if="patientData.referringGPId">
            <dt>General Practitioner</dt>
            <dd>{{ patientData.gpFullName }}</dd>
          </dl>
          <dl v-if="patientData.locationId">
            <dt>Facility</dt>
            <dd>{{ patientData.locationTitle }}</dd>
          </dl>
          <dl>
            <dt>Medicare Number</dt>
            <dd>{{ patientData.medicareNumber }}</dd>
          </dl>
          <dl>
            <dt>DVA Number</dt>
            <dd>{{ patientData.dvaNumber }}</dd>
          </dl>
          <dl>
            <dt>Pension Card Number</dt>
            <dd>{{ patientData.pensionCardNumber }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
  props: {
    patientData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showTextMessenger: false,
      textMessage: "",
      maxCharacters: 160,
      isLoading: false,
      messages: []
    }
  },
  computed: {
    age() {
      const birthDate = new Date(this.patientData.dob)
      const diff_ms = Date.now() - birthDate.getTime();
      const age_dt = new Date(diff_ms);

      return Math.abs(age_dt.getUTCFullYear() - 1970);
    },
    hasDoB() {
      if (this.patientData.dateOfBirth !== "N/A") {
        return true
      }
      return false
    },
    daysTilDob() {
      const birthDate = new Date(this.patientData.dob)

      const oneDay = 24 * 60 * 60 * 1000
      const secondDate = new Date(new Date().getFullYear() + 1, birthDate.getMonth() + 1, birthDate.getDate())
      const firstDate = new Date()
      return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay))
    },
    dateOfBirth() {
      if (this.patientData.dob) {
        const date = new Date(this.patientData.dob)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
      }
      return false
    }
  },
  methods: {
    emailPatient() {
      window.open(`mailto: ${this.patientData.email}`)
    },
    callPatient() {
      window.open(`tel: ${this.patientData.email}`)
    }
  }
}
</script>

<style scoped lang="scss">
.contact-details {
  h3 {
    font-weight: normal;
  }
  .flex-box {
    display: flex;

    .user-profile {
      flex: 1;
    }
    .overview {
      flex: 2;
      padding-left: 2em;

      .message-options {
        padding: 1em 0;
        text-align: right;

      }

      .contact-details {
        dl {
          display: flex;

          dt {
            flex: 1;
            font-weight: 500;
          }
          dd {
            flex: 2;
          }
        }
      }
    }

    .avatar {
      text-align: center;

      .patient-name {
        font-weight: 500;
      }
      .patient-id {
        color: var(--main-secondary);
      }
      .contact-options {
        padding: 1em 0 0 0;
      }
    }
  }
}

</style>