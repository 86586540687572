import PatientStatusColumn from "./PatientStatusColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "firstName",
      label: "First Name"
    },
    {
      prop: "lastName",
      label: "Last Name"
    },
    {
      prop: "dateOfBirth",
      label: "D.O.B."
    },
    {
      prop: "medicareNumber",
      label: "Medicare Number"
    },
    {
      prop: "locationTitle",
      label: "Facility"
    },
    {
      prop: "active",
      label: "Status",
      Component: PatientStatusColumn
    }
  ]

export default defaultColumns