import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class Site extends ExtendedModel {
  static entity = "Site"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Address() {
    return `${this.street} ${this.city} ${this.state} ${this.zip}`
  }

  get SiteContact() {
    return `${this.contactName} ${this.contactPhone} ${this.contactEmail} `
  }

  get Actions() {
    return new Actions(this)
  }

  get valueList() {
    const {id} = this
    return {label: this.title, value: id, id}
  }

}
export default Site
