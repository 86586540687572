import autobind from "auto-bind"
import get from "lodash/get"

class BulkActions {
  constructor(entity) {
    this.entity = entity

    autobind(this)
  }

  async createBulkPDF(records) {
    const { store } = this.entity

    const ids = records.map(({ id }) => id)

    const response = await this.entity.Api.createBulkPDF(ids)

    const fileLocation = get(response.response.data,"fileLocation")

    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        message: `Your Bulk PDF Zip has been created. <a href="${fileLocation}" target="_blank">Download PDF Zip</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating Bulk PDF",
        message: `Sorry, your Bulk PDF could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }

  async createBulkCSV(records) {
    const { store } = this.entity

    const ids = records.map(({ id }) => id)

    const response = await this.entity.Api.createBulkCSV(ids)

    const fileLocation = get(response.response.data,"fileLocation")

    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        message: `Your Bulk CSV has been created. <a href="${fileLocation}" target="_blank">Download CSV</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating Bulk CSV",
        message: `Sorry, your Bulk CSV could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }

  async createBulkEPCClaims(records) {
    const { store } = this.entity

    const ids = records.map(({ id }) => id)

    const response = await this.entity.Api.createBulkEPCClaims(ids)

    const fileLocation = get(response.response.data,"fileLocation")

    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        message: `Your Bulk PDF Zip has been created. <a href="${fileLocation}" target="_blank">Download EPC Claims Zip</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating Bulk EPC Claims",
        message: `Sorry, your Bulk EPC Claims could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }
  get list() {
    return [
      {
        visible: true,
        onClick: this.createBulkPDF,
        label: "Download PDFs",
        icon: "el-icon-download"
      },
      {
        visible: true,
        onClick: this.createBulkEPCClaims,
        label: "Download EPC Claims",
        icon: "el-icon-download"
      },
      {
        visible: true,
        onClick: this.createBulkCSV,
        label: "Download Bulk CSV",
        icon: "el-icon-download"
      }

    ].filter(( { visible }) => visible)
  }
}

export default BulkActions
