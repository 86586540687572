import PatientDialog from "./PatientDialog/PatientDialog"
import PatientDetails from "./PatientDetails/PatientDetails"
import ImportPatients from "./ImportPatientsDialog/ImportPatientsDialog"

export default [
  {
    name: "PatientDialog",
    Component: PatientDialog,
    permissions: "PatientManagement:patient:canRead"
  },
  {
    name: "PatientDetails",
    Component: PatientDetails,
    permissions: "PatientManagement:patient:canRead"
  },
  {
    name: "ImportPatients",
    Component: ImportPatients,
    permissions: "PatientManagement:import:canRead"
  }
]