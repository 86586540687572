export default {
  otoscopy: [
    {label:"Normal",
      value: "normal" },
    {label: "Slight wax occlusion",
      value: "slight_wax_occlusion"},
    {label: "Partial soft wax occlusion",
      value: "partial_soft_wax_occlusion"},
    {label: "Total soft wax occlusion" ,
      value: "total_soft_wax_occlusion"},
    {label: "Partial impacted wax occlusion",
      value: "partial_impacted_wax_occlusion"},
    {label: "Total impacted wax occlusion",
      value: "total_impacted_wax_occlusion"},
    {label: "Keratosis occlusion",
      value: "keratosis_occlusion"},
    {label: "Otorrhea",
      value: "otorrhea"},
    {label: "Foreign Object",
      value: "foreign_object"},
    {label: "Exostosis",
      value: "exostosis"},
    {label: "Tympanic membrane perforation",
      value: "tympanic_membrane_perforation"},
    {label: "Bleeding",
      value: "bleeding"},
    {label: "Severely impacted wax/keratosis",
      value: "severely_impacted_wax_keratosis"},
    {label: "Unable to view",
      value: "unable_to_view"},
    {label: "Other (Details)",
      value: "other"}
  ],
  hearingScreen: [
    {label: "Normal",
      value: "normal"},
    {label: "Mild Loss",
      value: "mild_loss"},
    {label: "Moderate Hearing Loss",
      value: "moderate_hearing_loss"},
    {label: "Severe Hearing Loss",
      value: "severe_hearing_loss"},
    {label: "Profound Hearing Loss",
      value: "profound_hearing_loss"},
    {label: "Mild to Moderate hearing loss",
      value: "mild_to_moderate_hearing_loss"},
    {label: "Mild to Severe hearing loss",
      value: "mild_to_severe_hearing_loss"},
    {label: "Mild to Profound hearing loss",
      value: "mild_to_profound_hearing_loss"},
    {label: "Moderate to Severe hearing loss",
      value: "moderate_to_severe_hearing_loss"},
    {label: "Moderate to Profound hearing loss",
      value: "moderate_to_profound_hearing_loss"},
    {label: "Severe to Profound hearing loss",
      value: "severe_to_profound_hearing_loss"},
    {label: "Normal to Moderate hearing loss",
      value: "normal_to_moderate_hearing_loss"},
    {label: "Normal to Severe hearing loss",
      value: "normal_to_severe_hearing_loss"},
    {label: "Normal to Profound hearing loss",
      value: "normal_to_profound_hearing_loss"},
    {label: "Other (Details)",
      value: "other"}
  ],
  actionTaken: [
    {label: "No Action Taken",
      value: "no_action_taken" },
    {label: "Occlusion successfully removed",
      value: "occlusion_successfully_removed"},
    {label: "Occlusion partially removed",
      value: "occlusion_partially_removed"},
    {label: "Hearing aid cleaned",
      value: "hearing_aid_cleaned"},
    {label: "Hearing Screen",
      value: "hearing_screen"},
    {label: "Counselled patient on hearing loss",
      value: "counselled_patient_on_hearing_loss"},
    {label: "Benefit from wax treatment not sufficient for procedure at this point in time.",
      value: "benefit_from_wax_treatment_not_sufficient_for_procedure_at_this_point_in_time"},
    {label: "Ear occlusion not significant enough to warrant treatment",
      value: "ear_occlusion_not_significant_enough_to_warrant_treatment"},
    {label: "Other",
      value: "other"},
  ],
  middleEarAssessment: [
    {label: "Normal",
      value: "normal" },
    {label: "Middle ear pathology",
      value: "middle_ear_pathology" },
    {label: "Eustachian tube dysfunction",
      value: "eustachian_tube_dysfunction" },
    {label: "Hypermobile tympanic membrane compliance",
      value: "hypermobile_tympanic_membrane_compliance" },
    {label: "Reduce tympanic membrane compliance",
      value: "reduce_tympanic_membrane_compliance" },
    {label: "Grommet/Perforation",
      value: "grommet_perforation" },
    {label: "N/A",
      value: "na" }
  ],
  recommendation: [
    {label: "Review 12 months",
      value: "review_12_months"},
    {label: "Review 6 months",
      value: "review_6_months"},
    {label: "Asymmetry - refer to ENT",
      value: "asymmetry_refer_to_ent"},
    {label: "Middle ear - pathology refer to ENT",
      value: "middle_ear_pathology_refer_to_ent"},
    {label: "Refer for diagnostic audiology",
      value: "refer_for_diagnostic_audiology"},
    {label: "Refer for hearing services",
      value: "refer_for_hearing_services"},
    {label: "Soften ear wax repeat appointment",
      value: "soften_ear_wax_repeat_appointment"},
    {label: "Foreign object refer to ENT",
      value: "foreign_object_refer_to_ent"},
    {label: "Issue TV headset",
      value: "issue_tv_headset"},
    {label: "Issue pocket talker",
      value: "issue_pocket_talker"},
    {label:  "Repair hearing aid/s",
      value: "repair_hearing_aids"},
    {label: "Reprogram hearing aid/s",
      value: "reprogram_hearing_aids"},
    {label: "Hearing aid unsuitable re-fit with higher power hearing aid",
      value: "hearing_aid_unsuitable_re_fit_with_higher_power_hearing_aid"},
    {label: "No further action",
      value: "no_further_action"},
    {label: "Provide consent form prior to procedure",
      value: "provide_consent_form_prior_to_procedure"},
    {label: "Administer wax softening solvent regime",
      value: "administer_wax_softening_solvent_regime"},
  ],
  epcClaimError: [
    {label: "Name details", value: "name_details"},
    {label: "Medicare number error", value: "medicare_number_error"},
    {label: "5 EPC claims", value: "5_epc_claims"},
    {label: "Medicare death error", value: "medicare_death_error"},
    {label: "No Referral", value: "no_referral"},
    {label: "Other", value: "other"},
    {label: "No Error", value: ""}
  ]
}