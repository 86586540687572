import { render, staticRenderFns } from "./TotalAppointmentNotes.vue?vue&type=template&id=9460181c&scoped=true"
import script from "./TotalAppointmentNotes.vue?vue&type=script&lang=js"
export * from "./TotalAppointmentNotes.vue?vue&type=script&lang=js"
import style0 from "./TotalAppointmentNotes.vue?vue&type=style&index=0&id=9460181c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9460181c",
  null
  
)

export default component.exports