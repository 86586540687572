<template>
  <div v-if="!isLoading">
    <core-data-table-v2
        :model="appointmentNotesModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="appointmentNotesManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { AppointmentNote, Patient, Location  } from "@/Modules/Models"
import columns from "../components/AppointmentNotesManagementTableView/columns"
import filterConfig from "../components/AppointmentNotesManagementTableView/filterConfig"
export default {
  name: "AppointmentNotesManagementTableView",
  data() {
    return {
      isLoading: false,
      filters: filterConfig
    }
  },
  computed: {
    appointmentNotesModel() {
      return AppointmentNote
    },
    columns() {
      return columns
    }
  },
  async created() {
    if (Location.all().length <= 0) {
      await Location.api().fetchAll()
    }
    if (Patient.all().length <= 0) {
      await Patient.api().fetchAll()
    }
    this.isLoading = false
  }
}
</script>
