export default {
  fields: {
    bookingDetails: {
      staffId: {
        type: "entity-select",
        props: {
          entity: "User",
          filterable: true,
          clearable: true,
          sortBy: {field: "firstName", order: "asc"},
        },
        validation: [{required: true, message: "Please select a staff member", trigger: "blur"}],
        label: "Select Staff Member",
      },
      locationId: {
        type: "entity-select",
        props: {
          entity: "Location",
          filterable: true,
          clearable: true,
          sortBy: {field: "title", order: "asc"},
        },
        validation: [{required: true, message: "Please select a facility", trigger: "blur"}],
        label: "Select Location",
      },
      bookingDate: {
        type: "date",
        value: null,
        label: "Booking Date",
        props: {
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd"
        },
        validation: [{required: true, message: "Please select a date", trigger: "blur"}]
      },
      placeholder: {
        type: "placeholder"
      },
      scheduledPatients: {
        width: 24,
        type: "textarea",
        value: null,
        label: "List of Patients. One patient (firstname lastname) per line",
        placeholder: "John Doe",
        validation: [{required: true, message: "Please enter patient names", trigger: "blur"}]

      }
    },
  }
}
