<template>
  <div class="appointment-notes-medicare-items-column-container">
    <el-tag
        v-for="item in scope.row.medicareItems"
        :key="item.id"
        size="mini">
      {{ item }}
    </el-tag>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "AppointmentPatientColumn",
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    medicareItems() {
      return this.scope.row.medicareItems
    }
  }
}
</script>
<style lang="scss" scoped>
  .appointment-notes-medicare-items-column-container {
    white-space: break-spaces;
  }
</style>