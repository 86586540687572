<template>
  <div class="medicare-items-container">
    <div
        class="flex-container">
      <div
          class="flex-column">
        <el-checkbox-group
            v-model="checkedItems">
          <el-checkbox
              v-for="item in col1"
              :key="item.id"
              :label="`${item.id} ${item.title}`"
              class="checkbox" />
        </el-checkbox-group>
      </div>

      <div class="flex-column">
        <el-checkbox-group
            v-model="checkedItems">
          <el-checkbox
              v-for="item in col2"
              :key="item.id"
              :label="`${item.id} ${item.title}`"
              class="checkbox" />
        </el-checkbox-group>
      </div>

      <div class="flex-column">
        <el-checkbox-group
            v-model="checkedItems">
          <el-checkbox
              v-for="item in col3"
              :key="item.id"
              :label="`${item.id} ${item.title}`"
              class="checkbox" />
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MedicareItems",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkedItems: [],
      col1: [
        {
          id: 10952,
          title: 'Audiology',
        },
        {  id: 93000,
          title: 'Audio Vid'
        },
        {  id: 93013,
          title: 'Audio Tel'
        }
      ],
      col2: [
        {
          id: 82306,
          title: 'ND Audio'
        },
        {
          id: 82309,
          title: 'AC PTA'
        },
        {
          id: 82312,
          title: 'AC + BC or SP'
        },
        {
          id: 82327,
          title: 'Tymps + PTA'
        },
      ],
      col3: [
        {
          id: 82315,
          title: 'AC, BC & SP'
        },
        {
          id: 82318,
          title: 'AC, BC, SP + Oth'
        },
        {
          id: 82324,
          title: 'Tymps solo'
        },
        {
          id: 82332,
          title: 'OAE'
        },
      ]
    }
  },
  watch: {
    checkedItems: {
      handler: function (val) {
        // find the item in the array and return its id
        const ids = val.map(item => {
          const id = item.split(' ')[0]
          return parseInt(id)
        })
        this.$emit('input', ids)
      },
      deep: true
    }
  },
  mounted() {
    if (this.value?.length) {
      this.show = true

      const allItems = [...this.col1, ...this.col2, ...this.col3]
      this.checkedItems = this.value.map(id => {
        const item = allItems.find(item => item.id === id)
        if (item) {
          return `${item.id} ${item.title}`
        }
        return null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  border: 1px solid #eaeaea;
  justify-content: space-between;

  .flex-column {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding: var(--padding-m);

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eaeaea;
      padding: var(--padding-m) var(--padding-l);
      margin: var(--margin-xs) 0;
      transition: var(--main-transition);

      &:hover {
        background: var(--color-bridj-light-background);
      }
    }
  }
}
</style>