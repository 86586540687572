<template>
  <div
      v-loading="isLoading"
      class="location-notes-container">
    <div class="left-panel">
      <div class="create-note-wrapper">
        <CreateNoteForm
            type="patient"
            :type-id="locationId"
            @createdNote="refreshNotes()" />
      </div>
    </div>
    <div class="right-panel">
      <div
          class="list-notes-wrapper">
        <ListNotes
            :refresh="refresh"
            type="patient"
            :type-id="locationId" />
      </div>
    </div>
  </div>
</template>

<script>
  import CreateNoteForm from "@/Modules/Core/components/Notes/components/CreateNoteForm"
  import ListNotes from "@/Modules/Core/components/Notes/components/ListNotes"
  import moment from "moment"

  export default {
    name: "LocationNotes",
    components: {
      CreateNoteForm,
      ListNotes
    },
    props: {
      locationId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        refresh: 0,
        moment: moment,
        reverse: true,
        notes: [],
        isLoading: false
      }
    },
    methods: {
      refreshNotes() {
        this.refresh = +new Date()
      }
    }
  }

</script>

<style lang="scss">
  .location-notes-container {
    display: flex;
    gap: 0 50px;
    justify-content: space-between;

    .left-panel {
      flex: 1
    }

    .right-panel {
      flex: 1
    }
    .list-notes-wrapper {
      border-left: 1px solid var(--main-border-color);
      padding: var(--padding-m);


    }
  }
</style>