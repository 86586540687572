<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <el-steps
          align-center
          :active="activeStep"
          finish-status="success">
        <el-step title="Create Patient" />                 
        <el-step title="Create Appointment" />        
      </el-steps>
      <template v-if="activeStep === 0">
        <PatientForm
            :dialog-props="dialogProps"
            :close-dialog="false"
            @nextStep="nextStep" />
      </template>
      <template v-else-if="activeStep === 1">
        <BookingManagementForm
            :dialog-props="dialogProps"
            :booking-data="bookingData"
            :patient-data="patientData" />
      </template>
    </template>
  </CoreDialog>
</template>

<script>
  import BookingManagementForm from "../../forms/BookingManagementForm/BookingManagementForm"
  import PatientForm from "@/Modules/PatientManagement/forms/PatientForm/PatientForm"
  
  import startCase from "lodash/startCase"

  export default {
    name: "PatientBookingDialog",
    components: {
      PatientForm,
      BookingManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true,
        activeStep: 0,
        patientData: null
      }
    },
    computed: {
      bookingData() {
        return null
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.bookingData) {
          return `${startCase(this.mode)}ing Patient and Booking ${this.bookingData.fullName} (ID: ${this.id})`
        }

        return "Create New Patient and Booking"
      }
    },
    mounted() {
      this.isLoading = false
    },
    methods: {
      nextStep(payload) {
        if (payload.status === "success" && payload.patientData) {
          this.patientData = payload.patientData
          this.activeStep = 1
        }
      }
    }
  }
</script>