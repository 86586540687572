import LocationsTableView from "./views/LocationsTableView"
import LocationsMapView from "./views/LocationsMapView"

const routes = [
  {
    path: "",
    name: "locations-table-view",
    component: LocationsTableView
  },
  {
    path: "table",
    name: "locations-table-view",
    component: LocationsTableView
  },
  {
    path: "map",
    name: "locations-map-view",
    component: LocationsMapView
  }
]

export default routes