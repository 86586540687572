import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    filename: null,
    progress: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition