import AppointmentNotesPdfColumn from "./AppointmentNotesPdfColumn"
import AppointmentNotesEPCClaimColumn from "./AppointmentNotesEPCClaimColumn"
import AppointmentNotesEPCClaimStatusColumn from "./AppointmentNotesEPCClaimStatusColumn"
import AppointmentNotesPatientColumn from "./AppointmentPatientColumn"
import AppointmentNotesMedicareItemsColumn from "./AppointmentNotesMedicareItemsColumn"
import AppointmentNotesAppointmentInfoColumn
  from "./AppointmentNotesAppointmentInfoColumn";

const defaultColumns = [
    {
      prop: "creationDate",
      label: "Created",
    },
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "patientName",
      label: "Patient",
      Component: AppointmentNotesPatientColumn,
    },
    {
      prop: "locationName",
      label: "Appointment Info",
      Component: AppointmentNotesAppointmentInfoColumn
    },
    {
      prop: "epcClaimed",
      label: "EPC claimed?",
      Component: AppointmentNotesEPCClaimStatusColumn
    },
    {
      prop: "medicareItems",
      label: "Medicare Items",
      Component: AppointmentNotesMedicareItemsColumn
    },
    {
      prop: "PDFAttachment",
      label: "PDF",
      Component: AppointmentNotesPdfColumn
    },
    {
      prop: "EPCClaimAttachment",
      label: "EPC Claim",
      Component: AppointmentNotesEPCClaimColumn
    }
  ]

export default defaultColumns