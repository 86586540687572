<template>
  <svg
      :fill="color"
      class="svg-icon"
      data-v-973dad08=""
      data-v-e8ef7130=""
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 63 78.75"
      enable-background="new 0 0 63 63"
      xml:space="preserve"
      data-v-63a2350a=""><path
          data-v-973dad08=""
          d="M49.9,35.7l-9.7-3.5c-1.2-0.5-2.4-1.6-2.6-2.5c4.3-4,6.9-11.5,6.9-16.3l0,0c0-7.2-5.8-13-13-13s-13,5.8-13,13l0,0l0,0  c0,4.7,2.7,12.2,6.9,16.3c-0.2,0.9-1.4,1.9-2.6,2.5l-9.7,3.5C8,37.6,4.5,42.5,4.5,47.9v14.6h54V47.9C58.5,42.5,55,37.6,49.9,35.7z   M48.5,47.5c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S47.4,47.5,48.5,47.5z M39,5.5c-1.3-0.1-2.6,0.2-3.7,0.9c-2.5,1.5-5.2,1.5-7.7,0  c-1.1-0.6-2.4-0.9-3.6-0.9c2-1.9,4.6-3,7.5-3C34.4,2.5,37.1,3.6,39,5.5z M20.5,13.5c0-2,0.5-3.8,1.5-5.5c1.5-0.8,3.2-0.7,4.6,0.1  c1.6,0.9,3.2,1.4,4.9,1.4c1.7,0,3.3-0.5,4.9-1.4c1.4-0.8,3.2-0.8,4.7,0l0,0c0.9,1.6,1.5,3.5,1.5,5.5c0,6.4-5.1,17-11,17  S20.5,19.9,20.5,13.5z M27.1,31.1c1.4,0.9,2.8,1.4,4.4,1.4c1.6,0,3.1-0.5,4.4-1.4c0.7,1.3,2.1,2.4,3.5,3l-4.2,10.8L34,38.6l2.1-4.1  h-9.2l2.1,4.2l-1.2,6.2l-4.2-10.8C24.9,33.5,26.4,32.4,27.1,31.1z M30.1,36.5h2.8L32,38.4l2,10.1l-2.4,6.3l-2.4-6.3l2-10L30.1,36.5z   M56.5,60.5h-50V47.9c0.4-8.7,8.1-10.7,8.1-10.7c1,2.3,1.3,4.8,0.9,7.3c-2.8,0.5-5,2.9-5,5.9v4h4v-2h-2v-2c0-2.2,1.8-4,4-4  s4,1.8,4,4v2h-2v2h4v-4c0-3-2.2-5.4-5-5.9c0.4-2.7,0-5.5-1-8l5.2-1.8l9.8,25.5l9.8-25.5l4.6,1.6c1.7,2.7,2.3,6,1.8,9.2  c-1.8,0.4-3.2,2-3.2,3.9c0,2.2,1.8,4,4,4s4-1.8,4-4c0-1.8-1.2-3.3-2.8-3.8c0.4-2.8,0-5.7-1.1-8.3c0,0,7.1,1.6,7.9,10.6V60.5z" /></svg>
</template>

<script>
  export default {
    name: "GeneralPractitionersManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>

<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>
