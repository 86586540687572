import BookingDialog from "./BookingDialog/BookingDialog"
import PatientBookingDialog from "./BookingDialog/PatientBookingDialog"
import ScheduledPatientsDialog from "./BookingDialog/ScheduledPatientsDialog";

export default [
  {
    name: "BookingDialog",
    Component: BookingDialog,
    permissions: "BookingManagement:Bookings:canCreate"
  },
  {
    name: "PatientBookingDialog",
    Component: PatientBookingDialog,
    permissions: "BookingManagement:Bookings:canCreate"
  },
  {
    name: "ScheduledPatientsDialog",
    Component: ScheduledPatientsDialog,
    permissions: "BookingManagement:ScheduledPatientsDialog:canCreate"
  }
]