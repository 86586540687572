const state = () => {
  return {
    currentDate: new Date(),
    reloadBookings: false,
    selectedLocationId: null,
    selectedStaffId: null,
  }
}

const actions = {
  updateCurrentDate({ commit }, payload) {
    commit("UPDATE_CURRENT_DATE", payload)
  },
  updateReloadBookings({ commit }, payload) {
    commit("UPDATE_RELOAD_BOOKINGS", payload)

    setTimeout(() => {
      commit("UPDATE_RELOAD_BOOKINGS", false)
    }, 400)
  },
  updateSelectedLocationId({ commit }, payload) {
    commit("UPDATE_SELECTED_LOCATION_ID", payload)
  },
  updateSelectedStaffId({ commit }, payload) {
    commit("UPDATE_SELECTED_STAFF_ID", payload)
  }
}

const mutations = {
  UPDATE_CURRENT_DATE(state, payload) {
    state.currentDate = payload
  },
  UPDATE_RELOAD_BOOKINGS(state, payload) {
    state.reloadBookings = payload
  },
  UPDATE_SELECTED_LOCATION_ID(state, payload) {
    state.selectedLocationId = payload
  },
  UPDATE_SELECTED_STAFF_ID(state, payload) {
    state.selectedStaffId = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
