export default {
  fields: {
    bookingDetails: {
      locationId: {
        type: "entity-select",
        props: {
          entity: "Location",
          filterable: true,
          clearable: true,
          sortBy: {field: "title", order: "asc"},
        },
        validation: [{required: true, message: "Please select a facility", trigger: "blur"}],
        label: "Select Location",
        value: null
      },

      patientId: {
        type: "entity-select",
        queryType: "filter",
        props(Form) {
          return {
            entity: "Patient",
            preFilter: { filterBy: "locationId", id: Form.formData.bookingDetails.locationId.value, required: true },
            sortBy: {field: "firstName", order: "asc"},
            filterable: true,
            clearable: true
          }
        },
        validation: [{required: true, message: "Please select a patient", trigger: "blur"}],
        label: "Select Patient",
        value: null
      },
      selectedUserId: {
        type: "entity-select",
        props: {
          entity: "User",
          filterable: true,
          clearable: true,
        },
        label: "Select Staff Member",
        value: null,
        validation: [{required: true, message: "Please select a staff member", trigger: "blur"}]
      },
      date: {
        type: "date",
        value: null,
        label: "Booking Date",
        props: {
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00"
        },
        validation: [{required: true, message: "Please select a date", trigger: "blur"}]
      }
    },
    misc: {
      _options: {
        rowSpan: 24,
        hideGroupTitle: true
      },
      notes: {
        type: "textarea",
        value: null,
        label: "Notes",
        placeholder: "Location, equipment, requirements etc."
      }
    }
  }
}
