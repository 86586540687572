<template>
  <div class="appointment-notes-appointment-info-column-container">
    <div v-if="scope.row.gpMedicareProviderNumber">
      <i
          class="el-icon-bank-card"
          title="Medicare Provider Number" /> {{ scope.row.gpMedicareProviderNumber }}
    </div>
    <div v-if="scope.row.gpfirstName || scope.row.gpLastName">
      <i class="el-icon-user" /> {{ scope.row.gpFirstName }} {{ scope.row.gpLastName }}
    </div>
    <div v-if="scope.row.locationName">
      <i class="el-icon-location" /> {{ scope.row.locationName }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "AppointmentPatientColumn",
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    medicareItems() {
      return this.scope.row.medicareItems
    }
  }
}
</script>
<style lang="scss" scoped>
  .appointment-notes-medicare-items-column-container {
    white-space: break-spaces;
  }
</style>