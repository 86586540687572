<template>
  <div class="column">
    <template v-if="scope.row.EPCClaimAttachment">
      <a
          :href="scope.row.EPCClaimAttachment"
          target="_blank">
        <span class="el-icon-document" />
        Download EPC Claim
      </a>
    </template>
  </div>
</template>
<script>
  export default {
    name: "AppointmentNotesEPCClaimColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
