import PatientTableView from "./views/PatientTableView"

const routes = [
  {
    path: "",
    name: "patient-list-view",
    component: PatientTableView
  }
]

export default routes