<template>
  <div class="appointment-notes">
    <template v-if="showNotes">
      <transition name="fade">
        <div class="appointment-notes-container">
          <div class="main-details">
            <dl>
              <dt>Date</dt>
              <dd>{{ appointmentNote.creationDateTime }}</dd>
            </dl>
            <dl v-if="location">
              <dt>Facility</dt>
              <dd>{{ location.title }}</dd>
            </dl>
          </div>

          <div class="results">
            <template v-for="(field, index) in fields">
              <dl
                  v-if="appointmentNote[field]"
                  :key="`field-${index}`">
                <dt>{{ field | convertStartCase }}</dt>
                <dd>{{ appointmentNote[field] | convertStartCase }}</dd>
              </dl>
            </template>
          </div>
        </div>
      </transition>
    </template>

    <div class="action-buttons">
      <el-button
          type="abel"
          size="small"
          @click="showNotes = !showNotes">
        <template v-if="!showNotes">
          Show
        </template>
        <template v-else>
          Hide
        </template>
        Notes
      </el-button>
      <template v-if="enableEdit">
        <el-button
            type="abel"
            icon="el-icon-edit"
            size="small"
            @click="editAppointmentNotes()">
          Edit
        </el-button>
      </template>
    </div>
  </div>
</template>


<script>
import startCase from "lodash/startCase"
import { Location } from "@/Modules/Models"

export default {
  name: "AppointmentNotes",
  filters: {
    convertStartCase(string) {
      return startCase(string)
    }
  },
  props: {
    appointmentNote: {
      type: Object,
      required: true
    },
    enableEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      showNotes: false,
      fields: [
        "otoscopyLeft",
        "otoscopyRight",
        "hearingScreenLeft",
        "hearingScreenRight",
        "actionTakenLeft",
        "actionTakenRight",
        "middleEarAssessmentLeft",
        "middleEarAssessmentRight",
        "recommendation",
        "extraNotes"
      ]
    }
  },
  computed: {
    location() {
      return Location.find(this.appointmentNote.locationId)
    }
  },
  methods: {
    editAppointmentNotes() {
      this.$emit("editAppointmentNotes", this.appointmentNote)
    }
  }
}
</script>

<style scoped lang="scss">
.appointment-notes-container {
  .results {
    border: 1px solid var(--main-light-grey);
    padding: 0.5em;
  }

  .main-details {
    display: flex;
    flex-wrap: wrap;
    background: var(--main-light-grey);
    padding: 0.5em;
    margin: 1em 0 0 0;

    dl {
      flex: 1;
    }
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;

    dt {
      flex: 1;
      font-weight: bold;
      text-align: right;
    }
    dd {
      flex: 2;
    }
  }
}
</style>