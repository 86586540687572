<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <ScheduledPatientsForm
          :dialog-props="dialogProps"
          :scheduled-patients-data="bookingData" />
    </template>
  </CoreDialog>
</template>

<script>
import ScheduledPatientsForm from "../../forms/ScheduledPatientsForm/ScheduledPatientsForm"
import { Booking } from "@/Modules/Models"
import startCase from "lodash/startCase"

export default {
  name: "ScheduledPatientsDialog",
  components: {
    ScheduledPatientsForm
  },
  props: {
    id: { type: [String, Number], default: null },
    closeDialog: { type: Function, required: true },
    dialogProps: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    bookingData() {
      return Booking.find(this.id)
    },
    mode() {
      return this.dialogProps.mode
    },
    title() {
      if (this.bookingData) {
        return `${startCase(this.mode)}ing Scheduled Patients List`
      }

      return "Create Scheduled Patients List"
    }
  },
  mounted() {
    if (this.id) {
      this.fetchBooking()
    } else {
      this.isLoading = false
    }
  },
  methods: {
    async fetchBooking() {
      this.isLoading = true

      //await Booking.api().fetchById(this.id)
      // replace with ScheduledPatients api
      this.isLoading = false
    }
  }
}
</script>