<template>
  <div class="weber-rinne-test-table-container">
    <el-switch
        v-model="show" /> Show Weber/Rinne Test Table
    <table v-show="show">
      <thead>
        <tr>
          <th colspan="3">
            Weber/Rinne Test
          </th>
        </tr>
        <tr>
          <th />
          <th>Rinne</th>
          <th>Weber</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Normal</td>
          <td>
            Bilat AC > BC <el-checkbox
                v-model="items.bilatACGreaterThanBC"
                true-label="1"
                false-label="0"
                class="checkbox" />
          </td>
          <td>
            Midline <el-checkbox
                v-model="items.midline"
                true-label="1"
                false-label="0"
                class="checkbox" />
          </td>
        </tr>
        <tr>
          <td>Conductive loss</td>
          <td>
            <div>
              BC > AC in L <el-checkbox
                  v-model="items.clBcAcInL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R <el-checkbox
                      v-model="items.clBcAcInR"
                      true-label="1"
                      false-label="0"
                      class="checkbox" />
            </div>
            <div>
              AC > BC in L <el-checkbox
                  v-model="items.clAcBcInL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R
              <el-checkbox
                  v-model="items.clAcBcInR"
                  true-label="1"
                  false-label="0"
                  class="checkbox" />
            </div>
          </td>
          <td>
            Lateralize to L <el-checkbox
                v-model="items.clLateralizeToL"
                true-label="1"
                false-label="0"
                class="checkbox" /> R
            <el-checkbox
                v-model="items.clLateralizeToR"
                true-label="1"
                false-label="0"
                class="checkbox" />
          </td>
        </tr>
        <tr>
          <td>Sensorineural hearing loss</td>
          <td>
            Bil AC > BC
          </td>
          <td>
            <div>
              Lateralize to L <el-checkbox
                  v-model="items.shLateralizeToL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R <el-checkbox
                      v-model="items.shLateralizeToR"
                      true-label="1"
                      false-label="0"
                      class="checkbox" />
            </div>
            <div>
              Lateralize away from L <el-checkbox
                  v-model="items.shLateralizeAwayFromL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R
              <el-checkbox
                  v-model="items.shLateralizeAwayFromR"
                  true-label="1"
                  false-label="0"
                  class="checkbox" />
            </div>
          </td>
        </tr>
        <tr>
          <td>Mixed hearing loss</td>
          <td>
            <div>
              BC > AC in L <el-checkbox
                  v-model="items.mhlBcAcInL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R
              <el-checkbox
                  v-model="items.mhlBcAcInR"
                  true-label="1"
                  false-label="0"
                  class="checkbox" />
            </div>
            <div>
              AC > BC in L <el-checkbox
                  v-model="items.mhlAcBcInL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R
              <el-checkbox
                  v-model="items.mhlAcBcInR"
                  true-label="1"
                  false-label="0"
                  class="checkbox" />
            </div>
          </td>
          <td>
            <div>
              Lateralize to L <el-checkbox
                  v-model="items.mhlLateralizeToL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R <el-checkbox
                      v-model="items.mhlLateralizeToR"
                      true-label="1"
                      false-label="0"
                      class="checkbox" />
            </div>
            <div>
              Lateralize away from L <el-checkbox
                  v-model="items.mhlLateralizeAwayFromL"
                  true-label="1"
                  false-label="0"
                  class="checkbox" /> R <el-checkbox
                      v-model="items.mhlLateralizeAwayFromR"
                      true-label="1"
                      false-label="0"
                      class="checkbox" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "WeberRinneTestTable",
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      items: {
        bilatACGreaterThanBC: null,
        midline: null,
        clBcAcInL: null,
        clBcAcInR: null,
        clAcBcInL: null,
        clAcBcInR: null,
        clLateralizeToL: null,
        clLateralizeToR: null,
        shLateralizeToL: null,
        shLateralizeToR: null,
        shLateralizeAwayFromL: null,
        shLateralizeAwayFromR: null,
        mhlBcAcInL: null,
        mhlBcAcInR: null,
        mhlAcBcInL: null,
        mhlAcBcInR: null,
        mhlLateralizeToL: null,
        mhlLateralizeToR: null,
        mhlLateralizeAwayFromL: null,
        mhlLateralizeAwayFromR: null,
      }
    }
  },
  watch: {
    items: {
      handler: function (val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  mounted() {
    if (this.value) {
      this.items = this.value;
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border: 1px solid #eaeaea;
    thead {
      th {
        background: #fafafa;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #eaeaea;
          border-right: 1px solid #eaeaea;

          &:last-child {
            border-right: none;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  .checkbox {
    margin: 0 5px;
  }
</style>