import GeneralPractitionersManagementStatusColumn from "./GeneralPractitionersManagementStatusColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "GP ID"
    },
    {
      prop: "fullName",
      label: "Name"
    },
    {
      prop: "email",
      label: "Email"
    },
    {
      prop: "phone",
      label: "Phone"
    },
    {
      prop: "mobile",
      label: "Mobile"
    },
    {
      prop: "medicareProviderNumber",
      label: "Medicare Prov Number",
    },
    {
      prop: "active",
      label: "Status",
      Component: GeneralPractitionersManagementStatusColumn
    }
  ]

export default defaultColumns