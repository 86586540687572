<template>
  <CoreDialog
      v-if="patientData"
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      width="70%">
    <template slot="content">
      <div class="patient-details">
        <el-tabs type="border-card">
          <el-tab-pane label="Contact Details">
            <ContactDetails              
                :patient-data="patientData" />
          </el-tab-pane>
          <el-tab-pane label="Appointments">
            <PatientAppointments :patient-data="patientData" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </CoreDialog>
</template>

<script>
import ContactDetails from "./PatientContactDetails"
import PatientAppointments from "./PatientAppointments"
import {Location, Patient} from "@/Modules/Models"

export default {
  name: "PatientDetails",
  components: {
    ContactDetails,
    PatientAppointments
  },
  props: {
    id: { type: [String, Number], default: null },
    closeDialog: { type: Function, required: true },
    dialogProps: { type: Object, required: true },
  },
  data() {
    return {
      messages: [],
      isLoading: true
    }
  },
  computed: {
    patientData() {
      return Patient.find(this.id)
    },
    title() {
      if (this.patientData) {
        return `Viewing Patient ${this.patientData.fullName} (ID: ${this.id})`
      }

      return "Create New Patient"
    }
  },
  async mounted() {
    if (Patient.all().length <= 0) {
      await Patient.api().fetchAll()
    }
    if (Location.all().length <= 0) {
      await Location.api().fetchAll()
    }
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep  .el-tabs__header {
    margin: 0.5em 0 !important;
  }
  ::v-deep  .el-tabs--border-card {
    border-radius: 0.5em;
  }
</style>
