<template>
  <fragment>
    <CoreScreen
        card
        class="generalPractitionersManagement-wrapper"
        header="General Practitioners"
        icon="GeneralPractitionersManagementIcon"
        :create-button="{ label: 'Create General Practitioner', onCreate: createGeneralPractitionersManagement }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { GeneralPractitioner } from "@/Modules/Models"
export default {
  name: "GeneralPractitionersManagement",
  methods: {
    createGeneralPractitionersManagement() {
      new GeneralPractitioner().Actions.openGeneralPractitionersManagementDialog()
    }
  }
}
</script>
