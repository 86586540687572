<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <PatientForm
          :dialog-props="dialogProps"
          :patient-data="patient" />
    </template>
  </CoreDialog>
</template>

<script>
  import PatientForm from "../../forms/PatientForm/PatientForm"
  import { Patient } from "@/Modules/Models"
  import startCase from "lodash/startCase"

  export default {
    name: "PatientDialog",
    components: {
      PatientForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      patient() {
        return Patient.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.patient) {
          return `${startCase(this.mode)}ing Patient ${this.patient.fullName} (ID: ${this.id})`
        }

        return "Create New Patient"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchPatient()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchPatient() {
        this.isLoading = true

        await Patient.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>

<style scoped>

</style>