import UserManagement from "@/Modules/UserManagement"
import AppointmentNotesManagement from "@/Modules/AppointmentNotesManagement"

// import ClientManagement from "@/Modules/ClientManagement"
import PatientManagement from "@/Modules/PatientManagement"
// import OrderManagement from "@/Modules/OrderManagement"
import BookingManagement from "@/Modules/BookingManagement"
import LocationManagement from "@/Modules/LocationsManagement"
import GeneralPractitionersManagement from "@/Modules/GeneralPractitionersManagement"

// import ClientAlbums from "@/Modules/ClientAlbums"
// import ProductManagement from "@/Modules/ProductManagement"
// import PointOfSale from "@/Modules/PointOfSale"
import SystemSettings from "@/Modules/SystemSettings"

export default [
  PatientManagement,
  BookingManagement,
  // PointOfSale,
  // OrderManagement,
  // ClientAlbums,
  // ProductManagement,
  AppointmentNotesManagement,
  GeneralPractitionersManagement,
  LocationManagement,
  UserManagement,
  SystemSettings
]
