import DayView from "./views/DayView"

const routes = [
  {
    path: "",
    name: "bookingManagement-day-view",
    component: DayView
  }
]

export default routes