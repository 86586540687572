import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/scheduledPatients"

class ScheduledPatients extends ExtendedModel {
  static entity = "ScheduledPatients"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async fetchByDate(date, options = {}) {
        const url = `${baseURL}${endpoint}/list/&filter[date]=${date}`
        return this.get(url, null)
      },
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get dateFormatted() {
    if (this.date) {
      return ScheduledPatients.convertDateForIOS(ScheduledPatients.formatDate(this.date))
    }
    return null
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default ScheduledPatients
