import LocationStatusColumn from "./LocationStatusColumn"

const defaultColumns = [
  {
    prop: "id",
    label: "Location ID"
  },
  {
    prop: "title",
    label: "Location"
  },
  {
    prop: "street",
    label: "Street"
  },
  {
    prop: "zip",
    label: "Post Code"
  },
  {
    prop: "state",
    label: "State"
  },
  {
    prop: "contactPhone",
    label: "Phone Number"
  },
  {
    prop: "status",
    label: "Status",
    Component: LocationStatusColumn
  }
]

export default defaultColumns