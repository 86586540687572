import LocationManagement from "./LocationManagement"
import LocationManagementIcon from "./components/LocationManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const LocationManagementModule = {
  moduleGrouping: "Customer Management",
  name: "LocationManagement",
  menuName: "Locations",
  component: LocationManagement,
  path: "LocationManagement",
  icon: LocationManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View reports and statistics here",
  enabled: true,
  dialogs,
  features,
  routes,
  children: routes,
  registerComponents: Vue => {
    Vue.component("LocationManagement", LocationManagement)
    Vue.component("LocationManagementIcon", LocationManagementIcon)
  }
}
export default LocationManagementModule
