<template>
  <div class="appointment-patient-column-container">
    {{ scope.row.patientFirstName }} {{ scope.row.patientLastName }}
    <template v-if="scope.row.patientDob && scope.row.patientDob !== '0000-00-00'">
      <br>
      <i
          class="el-icon-date"
          title="Date of Birth" /> <strong>{{ scope.row.patientDob | formatDate }}</strong>
    </template>
    <template v-if="scope.row.patientMedicareNumber">
      <br>
      <i
          class="el-icon-bank-card"
          title="Medicare Number" /> <strong>{{ scope.row.patientMedicareNumber }} {{ scope.row.patientMedicareReferenceNumber }}</strong>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "AppointmentPatientColumn",
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>