<template>
  <div
      class="login"
      :class="isLoggedIn">
    <div class="logo-container">
      <Logo />
    </div>
    <h1>Welcome to {{ appName }}!</h1>

    <div
        v-if="loginFormVisible"
        class="login-container">
      <h2>Please sign in to continue</h2>

      <CoreLoginForm
          v-model="loading"
          :set-animation-state="setAnimationState" />

      <div class="forgot-password-link">
        <router-link to="/Admin/ForgotPassword">
          I forgot my password
        </router-link>
      </div>
      <div class="app-footer-container">
        <AppFooter />
      </div>
    </div>

    <div
        v-else
        class="login-container flex-center">
      <core-text
          tag="h3"
          bold
          class="flex-center">
        {{ loadingText }}
      </core-text>
      <Auth0Logo
          v-if="auth0Loading"
          class="auth0-logo" />
    </div>



    <CoreWaveyMcWaveFace
        container-background="transparent"
        wave1="#5acbf3"
        wave2="#048ab9"
        wave3="#1e7b9c"
        wave4="var(--main-brand)"
        :animated="animated" />
    <div class="fill-container" />
  </div>
</template>

<script>
import CoreLoginForm from "../components/LoginForm"
import Logo from "../../../components/Branding/Logo"
import AppFooter from "@/Modules/Core/components/SideMenu/AppFooter"
import Auth0Logo from "../assets/SVG/Auth0Logo"
import get from "lodash/get"

export default {
  name: "Login",
  components: { CoreLoginForm, Logo, Auth0Logo, AppFooter },
  data() {
    return {
      isAnimated: false,
      loading: true,
      tokenChecked: false
    }
  },
  computed: {
    animated() {
      return this.loading || this.auth0Loading || this.isAnimated
    },
    loadingText() {
      if (this.auth0Loading) {
        return "Signing in with"
      }

      if (this.isLoggedIn || this.auth0Authenticated) {
        return "Sign in successful!"
      }

      return !this.tokenChecked ? "Checking token..." : "Signing in..."
    },
    appName() {
      return this.$store.state.settings.app.brandName
    },
    isLoggedIn() {
      return this.$store.state.auth.loggedIn
    },
    auth0Loading() {
      return get(this.$auth, "loading", false)
    },
    auth0Authenticated() {
      return get(this.$auth, "isAuthenticated", false)
    },
    loginFormVisible() {
      return !this.auth0Loading && !this.loading && !this.isLoggedIn && !this.auth0Authenticated
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reRoute()
        }
      }
    }
  },
  mounted() {
    this.reRoute()

    this.$store.dispatch("auth/checkToken").then(message => {
      if (!message.success) {
        this.loading = false
      } else {
        // this.loading = false
      }
    })
  },
  methods: {
    setAnimationState(isAnimated) {
      this.isAnimated = isAnimated
    },
    reRoute() {
      if (this.isLoggedIn) {
        const fromUrl = (localStorage.getItem("fromUrl") || "").replace("#", "")

        this.$router.push(fromUrl || "/").catch(e => {
          const errorPayload = {
            type: "Router Issue Login.vue (/Dashboard)",
            error: e,
            message: e,
            visible: true
          }

          this.$store.dispatch("core/errors/addToErrors", errorPayload)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth0-logo {
  width: 25% !important;
  margin-left: var(--margin-s);
}

.login {
  background: var(--main-white);
  height: 100vh;
  padding: 2em 0 0 0;
  /*overflow-y: hidden;*/

  h1 {
    text-align: center;
    color: var(--main-brand);
  }
  h2 {
    color: var(--main-primary-lighter);
  }
  .logo-container {
    text-align: center;
    width: 8em;
    margin: 2em auto;
    img {
      width: 100%;
    }
  }
  .login-container {
    background: #f1f3f8;
    box-shadow: var(--box-shadow-neumorphic);
    border-radius: 0.25em;
    padding: 2em;
    width: 30em;
    margin: auto;

    h2 {
      margin: 0 1em 1em 0.5em;
    }

    button {
      margin: 1em;
      border-radius: 0.25em;
    }
  }
  .forgot-password-link {
    padding: 1em;
    text-align: center;

    a {
      text-decoration: none;
      transition: var(--main-transition);

      &:hover {
        color: var(--main-primary-dark);
      }
    }
  }
  .fill-container {
    background: var(--main-brand);
    height: calc(100vh - 67em);
  }

  fieldset {
    border: 0;
    margin: 0 1em;
    background: linear-gradient(145deg, #eff0f5, #e6e9ed);
    box-shadow: 24px 25px 50px #ecedf0, -25px -25px 50px #ffffff,
      inset 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;

    legend {
      color: var(--main-primary-link);
      padding-top: 2.5em;
    }

    .scale-down {
      margin-left: 6em;
    }
  }
  .app-footer-container {
    ::v-deep  footer {
      background: transparent;
      padding: 1em 0 0 0;

      .environment-badge {
        background: rgba(0, 0, 0, 0.02);
        margin-top: 0;
      }

      .version-container {
        align-items: center;
        justify-content: center;

        .abel-logo {
          margin-left: 8em;
          svg {
            height: 1em;
          }
        }
      }
    }
  }
}
::v-deep  .waves {
  margin-bottom: -8px !important;
}
</style>
