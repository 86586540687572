import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/locations"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint
})

const apiActions = {
  ...defaults.apiActions
}

const routes = server => {
  defaults.routes(server)

  server.get(defaults.endpoints.base(), function(schema, request) {
    const locationSearch = get(request, 'queryParams["search"]')
    const status = get(request, 'queryParams["filter["approved"]"]')

    const applySearchCriteria = d => {
      let failingCriteria = []
      const location = schema.locations.find(d.id)

      const matchesLocationSearch = () => {
        const fullName = `${location.first_name} ${location.last_name}`
        const fullNameReversed = `${location.last_name} ${location.first_name}`
        const fieldsToMatch = [fullName, fullNameReversed, location.username, location.email]

        return fieldsToMatch.find(string => string.toLowerCase().includes(locationSearch))
      }

      if (locationSearch && !matchesLocationSearch()) {
        failingCriteria.push(false)
      }

      if (status && d.status !== status) {
        failingCriteria.push(false)
      }

      return !failingCriteria.length
    }
    return schema.locations.where(applySearchCriteria)

  })
}

export { apiActions }
export default routes
