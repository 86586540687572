export default {
  fields: {
    personalDetails: {
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        props: {
          placeholder: "ie. John"
        },
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        props: {
          placeholder: "ie. Doe",
        },
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      dob: {
        type: "date",
        value: null,
        label: "Date of Birth",
        props: {
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd",
          placeholder: "01/01/1980"
        }
      },
      locationId: {
        type: "entity-select",
        props: {
          entity: "Location",
          filterable: true,
          clearable: true,
        },
        label: "Select Location",
        value: null
      },
    },
    healthCareDetails: {
      medicareNumber: {
        type: "text",
        value: null,
        label: "Medicare Number",
        props: {
          placeholder: "ie. #### ##### #"
        },
        validation: [{required: false, message: "Please enter a valid Medicare number", trigger: "blur"}]
      },
      medicareReferenceNumber: {
        type: "text",
        value: null,
        width: 4,
        label: "Ref No.",
        props: {
          placeholder: "ie. 1"
        },
        validation: [{required: false, message: "Please enter a valid Medicare reference number", trigger: "blur"}]
      },
      dvaNumber: {
        type: "text",
        value: null,
        label: "DVA Number",
        props: {
          placeholder:"ie. #### ##### #"
        },
        validation: [{required: false, message: "Please enter a valid DVA Number", trigger: "blur"}]
      },
      pensionCardNumber: {
        type: "text",
        value: null,
        label: "Pension Card Number",
        props: {
          placeholder: "ie. #### ##### #"
        },
        validation: [{required: false, message: "Please enter a valid pension card number", trigger: "blur"}]
      },
      deceased: {
        type: "select",
        value: null,
        label: "Deceased?",
        options: [
          {label: "No", value: false},
          {label: "Yes", value: true}
        ]
      }
    },
    referralDetails: {
      referringGPId: {
        type: "entity-select",
        props: {
          entity: "GeneralPractitioner",
          filterable: true,
          clearable: true,
          suggestCreate: true,
          sortBy: { field: "firstName", order: "asc"}
        },
        label: "Referring GP",
        value: null
      },
      referralDate: {
        type: "date",
        value: null,
        props: {
          format: "dd/MM/yyyy",
          placeholder: "01/01/1980",
          defaultValue: new Date()
        },
        label: "Referral Date"
      }
    },
    other: {
      consent: {
        type: "select",
        value: null,
        label: "Consent given?",
        options: [
          {label: "No", value: false},
          {label: "Yes", value: true}
        ]
      },
      notes: {
        type: "textarea",
        value: null,
        label: "Notes"
      }
    }
  }
}
