import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    userId: null,
    locationId: null,
    bookingDate: null,
    scheduledPatients: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition