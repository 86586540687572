<template>
  <div class="column">
    <template v-if="scope.row.PDFAttachment">
      <a
          :href="scope.row.PDFAttachment"
          target="_blank">
        <span class="el-icon-document" />
        Download PDF
      </a>
    </template>   
  </div>
</template>
<script>
  export default {
    name: "AppointmentNotesPdfColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>
