<template>
  <div class="patient-appointments">
    <div class="block">
      <template v-if="hasAppointmentNotes">
        <el-timeline :reverse="reverse">
          <el-timeline-item
              v-for="(appointmentNote, index) in appointmentNotes"
              :key="`appointmentNote-${index}`"
              placement="top"
              :timestamp="appointmentNote.creationDateTime">
            <AppointmentNotes :appointment-note="appointmentNote" />
          </el-timeline-item>
        </el-timeline>
      </template>
      <template v-else>
        <core-text>
          There are no appointment notes for this patient.
        </core-text>
      </template>
    </div>
  </div>
</template>

<script>
import { AppointmentNote } from "@/Modules/Models"
import AppointmentNotes from "./AppointmentNotes"
import get from "lodash/get"

export default {
  name: "PatientAppointments",
  components: {
    AppointmentNotes
  },
  props: {
    patientData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reverse: true,
      appointmentNotes: [],
      loadedAppointmentNotes: false
    }
  },
  computed: {
    hasAppointmentNotes() {
      if (this.appointmentNotes.length) {
        return true
      }
      return false
    },
    patientDataId() {
      return get(this.patientData, "id")
    }
  },
  watch: {
    patientDataId: {
      handler(val) {
        if (val) {
          this.getAppointmentNotes()
        }
      },
      immediate: true
    }
  },
  methods: {
    creationTimeStamp(date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    convertDateForIOS(date) {
      const arr = date.split(/[- :]/)
      return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5])
    },
    async getAppointmentNotes() {
      if (!this.loadedAppointmentNotes) {
        this.isLoading = true
        await AppointmentNote.api().fetchByPatientId(this.patientData.id)
        this.isLoading = false

        this.appointmentNotes = AppointmentNote.query().where('patientId', this.patientData.id).get()

        this.loadedAppointmentNotes = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .el-timeline-item__timestamp{
  &.is-top {
    font-size: 1em;
    font-weight: bold;
  }
}
</style>