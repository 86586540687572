import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openSchedulePatientsDialog(options)
  }

  openEditSchedulePatientsDialog(options = {}) {
    const type = "ScheduledPatientsDialog"
    const { mode = "edit" } = options
    const { store } = this.entity

    const { id } = options
    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openSchedulePatientsDialog(options = {}) {
    const type = "ScheduledPatientsDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View Booking",
        icon: "el-icon-view",
        onClick: () => this.openSchedulePatientsDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Booking",
        icon: "el-icon-edit",
        onClick: () => this.openSchedulePatientsDialog({ mode: "edit" })
      },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this Scheduled Patients List?",
          confirmButtonText: "Delete Scheduled Patients List"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
