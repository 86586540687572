<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="updatedBookingData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
import {Booking} from "@/Modules/Models"
import formConfig from "./formConfig"
import get from "lodash/get"

export default {
  name: "BookingManagementForm",
  props: {
    dialogProps: {type: Object, required: true},
    bookingData: {
      type: Object, default: () => ({})
    },
    patientData: {
      type: Object, default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      updatedBookingData: {},
      formConfig
    }
  },
  computed: {
    mode() {
      return this.dialogProps.mode
    },
    optionsParams() {
      return this.dialogProps.optionsParams
    }
  },
  watch: {
    optionsParams: {
      handler() {
        if (get(this.optionsParams, "patientId")) {
          this.updatedBookingData.patientId = this.optionsParams.patientId
        }
        if (get(this.optionsParams, "locationId")) {
          this.updatedBookingData.locationId = this.optionsParams.locationId
        }
      },
      deep: true,
      immediate: true
    },
    patientData: {
      handler() {
        if (this.patientData) {
          if (this.patientData.locationId) {
            this.updatedBookingData.locationId = this.patientData.locationId
          }
          if (this.patientData.id) {
            this.updatedBookingData.patientId = this.patientData.id
            this.updatedBookingData.selectedUserId = this.$store.state.auth.user.uid
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.bookingData) {
      this.updatedBookingData = this.bookingData
    }

    // get selectedLocationId from store
    const selectedLocationId = this.$store.state.BookingManagement.selectedLocationId
    if (selectedLocationId) {
      this.updatedBookingData.locationId = selectedLocationId
    }

    // Either select the staff member from the store or use the current user
    const selectedStaffId = this.$store.state.BookingManagement.selectedStaffId || this.$store.state.auth.user.uid
    if (selectedStaffId) {
      this.updatedBookingData.selectedUserId = selectedStaffId
    }

    const currentDate = this.$store.state.BookingManagement.currentDate
    if (currentDate) {
      // format current date to 'YYYY-MM-DD 00:00:00'
      const formattedDate = new Date(currentDate)
      formattedDate.setHours(0, 0, 0, 0)
      // add locale offset
      formattedDate.setMinutes(formattedDate.getMinutes() - formattedDate.getTimezoneOffset())
      this.updatedBookingData.date = new Date(formattedDate)
    }
  },
  methods: {
    onCancel() {
      this.dialogProps.closeDialog()
    },
    async onSubmit(Form) {
      try {
        const flatFormData = Form.getFlatData()

        if (this.mode === "create") {
          await Booking.api().create(flatFormData)
        } else if (this.mode === "edit") {
          flatFormData.id = this.bookingData.id
          await Booking.api().update(flatFormData)
        }

        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank You",
          message: "Your Booking details have been successfully updated.",
          visible: true,
          decay: 3500
        }

        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

        this.dialogProps.closeDialog()
        this.$store.dispatch("BookingManagement/updateReloadBookings", true)
      } catch (error) {
        Form.setSubmitting(false)
        // @todo: handle error
      }
    }
  }
}
</script>