import BookingManagement from "./BookingManagement"
import BookingManagementIcon from "./components/BookingManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"
import store from "./store"

const BookingManagementModule = {
  moduleGrouping: "Management",
  name: "BookingManagement",
  menuName: "Bookings",
  component: BookingManagement,
  path: "BookingManagement",
  icon: BookingManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View BookingManagement data here",
  enabled: true,
  features,
  store,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("BookingManagement", BookingManagement)
    Vue.component("BookingManagementIcon", BookingManagementIcon)
  }
}
export default BookingManagementModule
