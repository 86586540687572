<template>
  <svg
      class="svg-icon"
      :fill="color"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 80"
      x="0px"
      y="0px">
    <g><path d="M31,9H28V6a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1V9H19a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h3v3a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V15h3a1,1,0,0,0,1-1V10A1,1,0,0,0,31,9Zm-1,4H27a1,1,0,0,0-1,1v3H24V14a1,1,0,0,0-1-1H20V11h3a1,1,0,0,0,1-1V7h2v3a1,1,0,0,0,1,1h3Z" /><path d="M62.215,17.72a3,3,0,0,0-5.5-.723L48,32.1V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V59a3,3,0,0,0,3,3H45a3,3,0,0,0,3-3V44.1L61.915,20A2.982,2.982,0,0,0,62.215,17.72ZM46,59a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H45a1,1,0,0,1,1,1V35.562L38.719,48.173a.962.962,0,0,0-.069.2,1.052,1.052,0,0,0-.045.1l-1.268,6.2a1,1,0,0,0,1.643.949l4.732-4.2a.95.95,0,0,0,.184-.227c.005-.008.014-.012.019-.021L46,47.562Zm-5.713-8.767,1.06.613L39.9,52.131Zm2.4-.926-1.732-1,15-25.98,1.732,1ZM60.183,19l-1.5,2.6-1.732-1,1.5-2.6a1,1,0,1,1,1.732,1Z" /></g>
  </svg>
</template>

<script>
export default {
  name: "AppointmentNotesManagementIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>
