const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Patient",
    options: [
      {
        label: "Patient Name",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.patient",
        inputType: "text",
        props: {
          placeholder: "Search by Patient name"
        }
      },
      {
        label: "Creation Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      },
      {
        label: "Appointment Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.appointmentDate",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Appointment Date"
        }
      }
    ]
  },
  {
    label: "Facility",
    options: [
      {
        label: "Facility",
        queryType: "search",
        urlKey: "filter.location",
        inputType: "entity-select",
        props: {
          entity: "Location"
        }
      }
    ]
  },
  {
    label: "GeneralPractitioner",
    options: [
      {
        label: "GeneralPractitioner",
        queryType: "search",
        urlKey: "filter.generalPractitioner",
        inputType: "entity-select",
        props: {
          entity: "GeneralPractitioner",
          placeholder: "Filter Patients by GP"
        }
      }
    ]
  }
]