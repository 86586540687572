<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="updatedScheduledPatientsData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
import { ScheduledPatients } from "@/Modules/Models"
import formConfig from "./formConfig"
import get from "lodash/get"

export default {
  name: "ScheduledPatientsForm",
  props: {
    dialogProps: {type: Object, required: true},
    scheduledPatientsData: {
      type: Object, default: () => ({})
    },
  },
  data() {
    return {
      isLoading: false,
      updatedScheduledPatientsData: {},
      formConfig
    }
  },
  computed: {
    mode() {
      return this.dialogProps.mode
    },
    optionsParams() {
      return this.dialogProps.optionsParams
    }
  },
  watch: {
    optionsParams: {
      handler() {
        if (get(this.optionsParams, "locationId")) {
          this.updatedScheduledPatientsData.locationId = this.optionsParams.locationId
        }
      },
      deep: true,
      immediate: true
    },
    patientData: {
      handler() {
        if (this.patientData) {
          if (this.patientData.locationId) {
            this.updatedScheduledPatientsData.locationId = this.patientData.locationId
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // get selectedLocationId from store
    const selectedLocationId = this.$store.state.BookingManagement.selectedLocationId
    if (selectedLocationId) {
      this.updatedScheduledPatientsData.locationId = selectedLocationId
    }
    const selectedStaffMember = this.$store.state.BookingManagement.selectedStaffId || this.$store.state.auth.user.uid
    if (selectedStaffMember) {
      this.updatedScheduledPatientsData.staffId = selectedStaffMember
    }

    const currentDate = this.$store.state.BookingManagement.currentDate
    if (currentDate) {
      // format current date to 'YYYY-MM-DD 00:00:00'
      const formattedDate = new Date(currentDate)
      formattedDate.setHours(0, 0, 0, 0)
      // add locale offset
      formattedDate.setMinutes(formattedDate.getMinutes() - formattedDate.getTimezoneOffset())
      this.updatedScheduledPatientsData.bookingDate = new Date(formattedDate)
    }
  },
  methods: {
    onCancel() {
      this.dialogProps.closeDialog()
    },
    async onSubmit(Form) {
      try {
        const flatFormData = Form.getFlatData()

        if (this.mode === "create") {
          // create array of scheduledPatients using '\n' as delimiter
          const scheduledPatientsArray = flatFormData.scheduledPatients.split('\n')

          flatFormData.scheduledPatients = scheduledPatientsArray.map(patient => {
            return {
              patient,
              status: 'scheduled'
            }
          })
          await ScheduledPatients.api().create(flatFormData)
        } else if (this.mode === "edit") {
          flatFormData.id = this.scheduledPatientsData.id
          await ScheduledPatients.api().update(flatFormData)
        }

        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank You",
          message: "Your Scheduled Patients have been successfully updated.",
          visible: true,
          decay: 3500
        }

        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

        this.dialogProps.closeDialog()
        this.$store.dispatch("BookingManagement/updateReloadBookings", true)
      } catch (error) {
        Form.setSubmitting(false)
        // @todo: handle error
      }
    }
  }
}
</script>