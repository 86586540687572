export default {
  fields: {
    locationDetails: {
      title: {
        type: "text",
        value: null,
        label: "Location Title",
        placeholder: "ie. St Andrews Hospital",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      placeholder: {
        type: "placeholder"
      },
      contactType: {
        type: "select",
        value: null,
        label: "Contact Type",
        options: [
          {label: "Clinical Manager", value: "clinicalManager"},
          {label: "Facility Manager", value: "facilityManager"},
          {label: "Registered Nurse", value: "registeredNurse"},
          {label: "Other", value: "Other"}
        ]
      },
      placeholder2: {
        type: "placeholder"
      },
      contactName: {
        type: "text",
        value: null,
        label: "Contact Name",
        placeholder: "ie. John Doe"
      },
      contactPhone: {
        type: "tel",
        value: null,
        label: "Phone Number",
        placeholder: "ie. +61 1000 1000"
      },
      contactEmail: {
        type: "email",
        value: null,
        label: "Email Address",
        placeholder: "ie. John.doe@example.org"
      },
      notes: {
        type: "text",
        value: null,
        label: "Notes",
        placeholder: "ie. Please ring 5min before arrival"
      }
    },
    addressDetails: {
      street: {
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 100 Main Street"
      },
      city: {
        type: "text",
        value: null,
        label: "City",
        placeholder: "ie. Sydney"
      },
      zip: {
        type: "text",
        value: null,
        label: "Post Code",
        placeholder: "ie. 2000"
      },
      state: {
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. New South Wales"
      },
      country: {
        type: "text",
        value: "Australia",
        label: "Country",
        placeholder: "ie. Australia"
      },
      status: {
        type: "select",
        filterable: true,
        value: null,
        label: "Location Status",
        options: [
          {label: "active", value: "active"},
          {label: "inactive", value: "inactive"}
        ],
      }
    }
  }
}
