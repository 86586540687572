import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    title: null,
    street: null,
    city: null,
    zip: null,
    state: null,
    country: null,
    contactType: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    notes: null,
    status: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition