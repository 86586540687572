export default {
  fields: {
    PersonalDetails: {
      medicareProviderNumber: {
        type: "text",
        value: null,
        label: "Medicare Provider Number",
        placeholder: "ie. 1000 0000 0000",
        width: 24
      },
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        placeholder: "ie. John",
        validation: [{ required: true, message: "Please enter first name", trigger: "blur" }]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        placeholder: "ie. Doe"
      },
      phone: {
        type: "tel",
        value: null,
        label: "Phone Number",
        placeholder: "ie. +61 1000 1000"
      },
      mobile: {
        type: "tel",
        value: null,
        label: "Mobile Number",
        placeholder: "ie. +61 411 111 111"
      },
      email: {
        type: "email",
        value: null,
        label: "Email Address",
        placeholder: "ie. John.doe@example.org"
      }
    },
    addressDetails: {
      street: {
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Street"
      },
      city: {
        type: "text",
        value: null,
        label: "City",
        placeholder: "Sydney"
      },
      zip: {
        type: "text",
        value: null,
        label: "Postcode",
        placeholder: "ie. 2000"
      },
      country: {
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. Australia",
        initialValue: "Australia"
      }
    },
    additionalDetails: {
      nursePractitioner: {
        type: "text",
        value: null,
        label: "Practitioner's Nurse",
        placeholder: "ie. Jane Doe"
      },
      notes: {
        type: "text",
        value: null,
        label: "Notes",
        placeholder: "ie. Please ring 5min before arrival"
      },
      status: {
        type: "select",
        filterable: true,
        value: null,
        label: "GP's Status",
        options: [
          {label: "active", value: "active"},
          {label: "inactive", value: "inactive"}
        ],
      }
    }
  }
}
