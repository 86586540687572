<template>
  <div class="column">
    <template v-if="scope.row.epcClaimed">
      <el-tag
          :type="(scope.row.epcClaimed === '1' && (scope.row.epcError == null || scope.row.epcError === '')) ? 'success' : 'danger'"
          disable-transitions>
        {{ status }}
        <template v-if="scope.row.epcError">
          with error
          <el-tooltip
              class="item"
              effect="dark"
              :content="`EPC Error: ${scope.row.epcError}`"
              placement="top">
            <i class="el-icon-info" />
          </el-tooltip>
        </template>
      </el-tag>
    </template>
  </div>
</template>
<script>
  export default {
    name: "AppointmentNotesEPCClaimStatusColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
    computed: {
      status() {
        if (this.scope.row.epcClaimed === "1") {
          return "Yes"
        } else {
          return "No"
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
