<template>
  <fragment>
    <CoreScreen
        card
        class="location-management-wrapper"
        header="Locations"
        icon="LocationManagementIcon"
        :tabs="tabs"
        :create-button="{ label: 'Create Location', onCreate: createLocation }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
  import { Location } from "@/Modules/Models"
  export default {
    name: "LocationManagement",
    computed: {
      tabs() {
        return [
          {
            label: "Table View",
            to: {
              name: "locations-table-view"
            }
          },
          {
            label: "Map View",
            to: {
              name: "locations-map-view"
            }
          },
        ]
      }
    },
    methods: {
      createLocation() {
        new Location().Actions.openLocationDialog()
      }
    }
  }
</script>
