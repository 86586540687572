<template>
  <div class="CreateNoteForm-container">
    <h3>Create a Note</h3>
    <el-form
        ref="noteForm"
        :model="form"
        :rules="rules"
        label-width="120px">
      <el-form-item
          label="Title"
          prop="title">
        <el-autocomplete
            v-model="form.title"
            popper-class="my-autocomplete"
            :fetch-suggestions="searchTitle"
            placeholder="Select or enter a title"
            @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="value">
              {{ item }}
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item
          label="Description"
          prop="description">
        <el-input
            v-model="form.description"
            type="textarea" />
      </el-form-item>

      <el-form-item>
        <el-button @click="onCancel">
          Cancel
        </el-button>

        <el-button
            type="success"
            @click="onSubmit">
          Create Note
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { Note } from "@/Modules/Models"
  export default {
    name: "CreateNoteForm",
    props: {
      type: {
        type: String,
        default: ''
      },
      typeId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isLoading: false,
        form: {
          title: '',
          description: ''
        },
        rules: {
          title: [
            {required: true, message: 'Please enter a title', trigger: 'blur'}
          ],
          description: [
            {required: true, message: 'Please enter a description', trigger: 'blur'}
          ],
        }
      }
    },
    methods: {
      handleSelect(item) {
        this.form.title = item
      },
      createFilter(queryString) {
        return (title) => {
          return (title.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        };
      },
      searchTitle(queryString, cb) {
        const titles = ['Telephone Call', 'Text Message', 'Video Call', 'Personal Meeting', 'Letter']
        const results = queryString ? titles.filter(this.createFilter(queryString)) : titles

        // call callback function to return suggestion objects
        cb(results);
      },
      onCancel() {
        this.$refs['noteForm'].resetFields()
      },
      async onSubmit() {

        this.$refs['noteForm'].validate(async (valid) => {
          if (valid) {
            this.isLoading = true

            const flatFormData = {
              type: this.type,
              typeId: this.typeId,
              title: this.form.title,
              description: this.form.description,
            }
            const response = await Note.api().create(flatFormData)

            let notificationsPayload

            if (response.response.data.success) {
              notificationsPayload = {
                component: "Toast",
                type: "success",
                title: "Thank You",
                message: "Your note has been successfully created.",
                visible: true,
                decay: 3500
              }
            }
            else {
              notificationsPayload = {
                component: "Toast",
                type: "error",
                title: "Error",
                message: response.response.data.error,
                visible: true,
                decay: 3500
              }
            }

            this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
            this.$emit("createdNote")

            this.isLoading = false
            this.onCancel()
          } else {
            return false
          }
        })
      }
    }
  }
</script>
