// import TheStylex from "@/Modules/TheStylexBase/config"
// import Lightwerk from "@/Modules/LightwerkBase/config"
import AppBase from "@/Modules/OtocareBase/config"
// import JKWMemberPortal from "@/Modules/JKWMemberPortalBase/config"
// import AppBase from '@/Modules/CaneToadEquipAdminBase/config'
// import AppBase from '@/Modules/FMSBase/config'
// import AppBase from '@/Modules/FMSClientBase/config'
// import AppBase from '@/Modules/MHJConfiguratorBase/config'

export default AppBase
