<template>
  <div
      :key="selectedDateFormatted"
      class="staff-view-container">
    <div class="day-header">
      <div class="day">
        <el-button
            size="small"
            @click="changeDay('back')">
          <i class="el-icon-arrow-left" />
        </el-button>
        <h3>
          {{ dayName }},
          <el-date-picker
              v-model="selectedDate"
              format="dd MMMM yyyy"
              type="date"
              placeholder="Pick a day" />
        </h3>
        <el-button
            size="small"
            @click="changeDay()">
          <i class="el-icon-arrow-right" />
        </el-button>
      </div>
      <div class="staff-selector">
        <ElForm label-position="top">
          <label for="select-staff">
            Staff Member
          </label>
          <ElSelect
              id="select-staff"
              v-model="selectedStaffId"
              filterable
              placeholder="Select Staff Member">
            <ElOption
                v-for="(staff, staffIndex) in staffMembers"
                :key="`staff-${staffIndex}`"
                :label="staff.firstName"
                :value="staff.id" />
          </ElSelect>
        </ElForm>
      </div>
      <div class="facility-selector">
        <ElForm label-position="top">
          <label for="select-facility">
            Facility
          </label>
          <ElSelect
              id="select-staff"
              v-model="selectedLocationId"
              placeholder="Select Location"
              filterable
              clearable>
            <ElOption
                v-for="(location, locationIndex) in locations"
                :key="`location-${locationIndex}`"
                :label="location.title"
                :value="location.id" />
          </ElSelect>
        </ElForm>
      </div>
    </div>

    <div v-if="isLoading">
      Loading ...
    </div>
    <div
        v-else
        class="content-container">
      <!-- Bookings per Staff Member -->
      <div
          class="grid-container">
        <div
            v-for="(staff, staffIndex) in filteredStaffMembers"
            :key="`staff-${staffIndex}`"
            class="staff">
          <div class="header">
            {{ staff.firstName }}
          </div>
          <div class="booking-container">
            <template v-for="(booking, bookingIndex) in getBookingsPerStaffMember(staff.id)">
              <div
                  :key="`booking-${bookingIndex}`"
                  class="booking">
                <BookingDetails
                    :booking="booking"
                    @editClientBooking="editClientBooking"
                    @refreshBookings="handleRefreshBookings" />
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Scheduled Patients-->
      <div class="scheduled-patients">
        <el-card class="box-card">
          <div
              slot="header"
              class="clearfix">
            <strong class="header-title">
              Scheduled Patients
            </strong>
            <el-button
                style="float: right; padding: 3px 0"
                type="text"
                @click.prevent="handleSchedulePatients">
              + Schedule Patients
            </el-button>
          </div>
          <div
              v-for="(spi, index) in scheduledPatientsItem"
              :key="`spi-${index}`"
              class="text item">
            <strong class="facility">
              Facility: {{ spi.locationTitle }} ({{ spi.locationId }})
            </strong>
            <ul
                v-for="(patientObj, patientIndex) in spi.scheduledPatients"
                :key="`patient-${patientIndex}`">
              <li
                  class="patient">
                <span :class="patientObj.status">
                  {{ patientObj.patient }}
                </span>

                <div class="actions">
                  <ElButton
                      v-if="patientObj.status === 'scheduled'"
                      size="mini"
                      type="success"
                      @click.prevent="handleCreatePatient(patientObj)">
                    Create Patient
                  </ElButton>
                  <ElButton
                      v-if="patientObj.status === 'scheduled'"
                      size="mini"
                      type="danger"
                      @click.prevent="handleRejectPatient(patientObj)">
                    Reject
                  </ElButton>
                </div>
              </li>
            </ul>
            <br>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {Booking, Client, Patient, Settings, User, Location, ScheduledPatients } from "@/Modules/Models"
import BookingDetails from "@/Modules/BookingManagement/components/BookingDetails"
import moment from "moment"

export default {
  name: "DayView",
  components: {
    BookingDetails
  },
  data() {
    return {
      selectedDate: new Date(),
      currentDate: new Date(),
      bookings: null,
      staffMembers: null,
      settings: null,
      selectedStaffId: null,
      locations: null,
      scheduledPatientsItem: null,
    }
  },
  computed: {
    isLoading: {
      get() {
        return !(this.bookings && this.settings && this.staffMembers);
      },
      set(val) {
        return val
      }
    },
    currentUserId() {
      return this.$store.state.auth.user?.uid || null
    },
    filteredStaffMembers() {
      if (this.selectedStaffId) {
        return this.staffMembers?.filter(staff => staff.id === this.selectedStaffId)
      }
      return this.staffMembers
    },
    monthName() {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ]
      const d = this.selectedDate
      return `${monthNames[d.getMonth()]} ${d.getFullYear()}`
    },
    selectedDateFormatted() {
      return this.selectedDate.getDate()
    },
    dateFormattedSessions() {
      if (this.bookings) {
        const bookings = JSON.parse(JSON.stringify(this.bookings))
        let startTime = moment().toDate();  // This will return a copy of the Date that the moment uses

        startTime.setHours(9);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        let endTime = moment().toDate();  // This will return a copy of the Date that the moment uses

        endTime.setHours(10);
        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);


        bookings.map((booking, index) => {
          if (!booking.startTime) {

            let newStartTime = new Date(startTime.setHours(startTime.getHours() + index))
            booking.startTime = `${newStartTime.getHours()}:${newStartTime.getMinutes()}`
          }
          if (!booking.endTime) {
            let newEndTime = new Date(endTime.setHours(endTime.getHours() + index))
            booking.endTime = `${newEndTime.getHours()}:${newEndTime.getMinutes()}`
          }
          booking.formattedStartTime = booking.startTime.substring(0, 2)
          booking.marginTop = booking.startTime.substring(3, 5)
        })
        return bookings
      }
      return null
    },
    hours() {
      if (this.settings) {
        const startingHours = this.settings.startingTime.substring(0, 2)
        const finishingHours = this.settings.finishingTime.substring(0, 2)
        const hours = []
        for (let i = startingHours; i <= finishingHours; i++) {
          hours.push(i)
        }
        return hours
      }
      return null
    },
    dayName() {
      const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return names[this.selectedDate.getDay()]
    },
    reloadBookings() {
      return this.$store.state.BookingManagement.reloadBookings
    },
    selectedLocationId: {
      get() {
        return this.$store.state.BookingManagement.selectedLocationId
      },
      set(val) {
        this.$store.dispatch('BookingManagement/updateSelectedLocationId', val)
      }
    }
  },
  watch: {
    //immediate watch currentUserId
    currentUserId: {
      immediate: true,
      handler() {
        this.selectedStaffId = this.currentUserId
      }
    },
    selectedStaffId() {
      this.$store.dispatch("BookingManagement/updateSelectedStaffId", this.selectedStaffId)
      this.getScheduledPatients()
    },
    reloadBookings() {
      this.getBookings()
      this.getScheduledPatients()
    },
    selectedDate() {
      this.isLoading = true
      this.getSettings()
      this.getStaff()
      this.getBookings()
      this.getScheduledPatients()
    },
    selectedLocationId() {
      this.getScheduledPatients()
    }
  },
  created() {
    this.getSettings()
    this.getStaff()
    this.getBookings()
    this.getLocations()
    this.getScheduledPatients()
  },
  methods: {
    handleSchedulePatients() {
      new Booking().Actions.openSchedulePatientsDialog()
    },
    handleRefreshBookings() {
      this.getBookings();
    },
    async getSettings() {
      await Settings.api().fetchById()
      this.settings = Settings.find(1)
    },
    async getStaff() {
      await User.api().fetchAll()
      const result = User.all()
      // sort staff by first name
      this.staffMembers = result.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
    async getBookings() {
      const results = await Booking.api().fetchByDate(this.createSQLDate(this.selectedDate))
      this.bookings = results.response.data.data.data
    },
    async getLocations() {
      await Location.api().fetchAll()
      let result = Location.all()
      // sort locations by title
      this.locations = result.sort((a, b) => a.title.localeCompare(b.title))
    },
    async getScheduledPatients() {
      const query = {
        locationId: this.selectedLocationId,
        date: this.selectedDate,
        staffId: this.selectedStaffId,
        userId: this.currentUserId
      }
      const result = await ScheduledPatients.api().fetchAll({ params: { filter: query }})
      let tempScheduledPatients = result.response.data.data.data

      // Create a Map to store grouped patients
      const groupedPatients = new Map()

      tempScheduledPatients.forEach(scheduledPatient => {
        const location = this.locations?.find(location => location.id === scheduledPatient.locationId)
        const locationTitle = location?.title

        if (!groupedPatients.has(scheduledPatient.locationId)) {
          groupedPatients.set(scheduledPatient.locationId, {
            locationId: scheduledPatient.locationId,
            locationTitle: locationTitle,
            bookingDate: scheduledPatient.bookingDate,
            scheduledPatients: []
          })
        }

        // Flatten the scheduledPatients array
        scheduledPatient.scheduledPatients.forEach(patient => {
          groupedPatients.get(scheduledPatient.locationId).scheduledPatients.push({
            patient: patient.patient,
            status: patient.status,
            userId: scheduledPatient.userId,
            staffId: scheduledPatient.staffId
          })
        })
      })

      // Convert Map to array of objects
      this.scheduledPatientsItem = Array.from(groupedPatients.values())
    },
    createSQLDate(dateString) {
      const date = new Date(dateString)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
    },
    addAppointmentNotes(booking) {
      this.$emit("addAppointmentNotes", booking)
    },
    createBooking(staffId) {
      this.$emit("createBooking", this.selectedDate, staffId)
    },
    editClientBooking(booking) {
      new Booking().Actions.openEditBookingDialog(booking)
    },
    calculatedWidth(bookings) {
      const percentage = 100 / bookings.length
      return `width: ${percentage}%`
    },
    changeDay(direction = "forward") {
      if (direction === "forward") {
        this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 1))
      } else {
        this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 1))
      }
      this.$store.dispatch("BookingManagement/updateCurrentDate", this.selectedDate)
    },
    formatDate(date) {
      const dateString = new Date(date)
      const day = dateString.getDate()
      const month = dateString.getMonth() + 1
      const year = dateString.getFullYear()
      return `${day}/${month}/${year}`
    },
    getBookingsPerStaffMember(staffMemberId) {
      const findDate = this.formatDate(this.selectedDate)
      let foundBookings = []
      this.dateFormattedSessions.forEach(item => {
        item.dateFormatted = this.formatDate(item.date)
        // console.log("item dateFormatted", item.dateFormatted)
        // console.log("item.formattedStartTime", item.formattedStartTime)

        if (item.dateFormatted === findDate
            && parseInt(item.selectedUserId) === parseInt(staffMemberId)) {

          if (item.clientId) {
            item.client = Client.find(item.clientId)
          }
          if (item.patientId) {
            item.patient = Patient.find(item.patientId)
            item.client = Patient.find(item.patientId)
          }
          foundBookings.push(item)
        }
      })
      foundBookings.sort((a, b) => (parseInt(a.startTime) > parseInt(b.startTime)) ? 1 : -1)

      return foundBookings
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-view-container {
  overflow-x: auto;
  max-width: 100%;

  .day-header {
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date {
      flex: 1;
      .tall {
        font-size: 1.2em;
      }
    }
    .day {
      flex: 2;
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  .content-container {
    display: flex;
    gap: 1rem;
  }

  .grid-container {
    width: 80%;
    display: flex;
    padding: 0;
    margin: 1em 0 1em 3em;

    .staff {
      flex: 1;
      justify-content: center;
      min-width: 15em;
      border-right: 1px solid #EBEEF5;
      position: relative;
      max-width: 50%;

      .day-options {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
      }

      &:first-child {
        border-left: 1px solid #EBEEF5;
      }

      .header {
        border-top: 1px solid #EBEEF5;
        padding: 1em 0;
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
      }

      .booking-container {
        width: 100%;
        border-bottom: 1px solid #ebeef5;

        .booking {
          width: 100%;
          margin: 1px;
          &:hover {
            z-index: 1000;
          }
        }
      }

      .hour {
        border-bottom: 1px solid #EBEEF5;
        height: 60px;
        position: relative;
        .time {
          top: -1.85em;
          left: -3em;
          padding-right: 0.75em;
          position: absolute;
          border-bottom: 1px solid #EBEEF5;
        }

      }
    }
  }

  .scheduled-patients {
    width: 20%;
    min-width: 30rem;

    .header-title {
      font-size: 1.2rem;
    }

    .facility {
      font-size: 1.1rem;
      border-bottom: 1px solid #EBEEF5;
      display: block;
      margin-bottom: 0.5rem;
    }
    .patient {
      font-size: 1rem;
      padding: 0.5rem 0;
      transition: background-color 0.3s;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span.rejected {
        //strike through
        text-decoration: line-through;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
</style>